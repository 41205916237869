<template>
  <vbt-dialog
    v-model="dialog"
    hide-submit-btn
    width="450px"
  >
    <template #title>
      <v-layout justify-center>
        <h3>
          {{ `Barcode for ${trackingNumber}` }}
        </h3>
      </v-layout>
    </template>

    <v-layout
      justify-center
      class="pt-3"
    >
      <img id="trackingBarcode" />
    </v-layout>
  </vbt-dialog>
</template>

<script>
const JsBarcode = require('jsbarcode');

export default {
  name: 'IrReturnedPackageTrackBarcode',

  data() {
    return {
      trackingNumber: '',
      dialog: false,
    };
  },

  methods: {
    $_openDialog(trackingNumber) {
      this.trackingNumber = trackingNumber;
      this.dialog = true;

      setTimeout(() => JsBarcode('#trackingBarcode', trackingNumber), 0);
    },
  },
};
</script>
