<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <v-btn
        :class="color"
        x-small
        icon
        v-on="on"
        @click="$emit('click')"
      >
        <v-icon x-small>
          reorder
        </v-icon>
      </v-btn>
    </template>

    {{ tooltip }}
  </v-tooltip>
</template>

<script>
export default {
  name: 'IrReturnedPackageTrackBarcodeViewerActivator',

  props: {
    color: {
      type: String,
      default: 'blue--text darken-1',
    },

    tooltip: {
      type: String,
      default: 'Show barcode',
    },
  },
};
</script>
